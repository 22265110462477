<template>
  <div class="personal">
    <el-row type="flex" class="top_logo" justify="center" align="middle" style="padding:20px 0">
      <el-col :lg="6" :xl="6">
        <img src="../assets/img/LOGO-2.png" style="margin-left: 10px;" />
      </el-col>
      <el-col :lg="19" :xl="19" class="right_title">
        <el-col :lg="4" :xl="4" class="shu"></el-col>
        <el-col
          :lg="12"
          :xl="12"
          style="font-size: 32px;padding-left: 10px;line-height: 66px;display:flex;"
        >政府监管机构专区</el-col>
        <el-col :lg="14" :xl="14">
          <el-row type="flex" justify="end">
            <el-col class="registered" :lg="10" :xl="10">没有账号，立即</el-col>
            <el-col :lg="4" :xl="4" class="registered">
              <span>注册</span>
            </el-col>
          </el-row>
        </el-col>
      </el-col>
    </el-row>
    <img src="../assets/img/73.png" style=" width: 100%;object-fit: contain;" />
    <el-row class="login_zone">
      <el-col class="login_box">
        <el-row type="flex" class="login-title">
          <el-col :span="12" class="active">账户登录</el-col>
          <el-col :span="12">扫码登录</el-col>
        </el-row>
        <el-row style="margin-top:20px;">
          <el-col>
            <el-row type="flex" align="middle" class="login_item">
              <el-col :span="3" class="icon_box">
                <img src="../assets/img/67.png" />
              </el-col>
              <el-col style="background: #fff;">
                <input placeholder="请输入您的登录账号" v-model="username" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-row type="flex" align="middle" class="login_item">
              <el-col :span="3" class="icon_box">
                <img src="../assets/img/68.png" />
              </el-col>
              <el-col style="background: #fff;">
                <input placeholder="请输入登录密码" type="password" v-model="password" />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="forget">
          <el-col>
            <span>忘记密码</span>
          </el-col>
        </el-row>
        <el-row class="login_btn">
          <el-col><div @click="login()" >登录</div></el-col>
        </el-row>
        <el-row class="forget">
          <el-col>
            <span>免费注册</span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  CollectLogin
} from '../api/article.js'
export default {
  data(){
  return{
    username:'',
    password:''
  }
},
methods:{
login(){
  CollectLogin(this.username,this.password).then(res=>{
    if(res.data.status ==0){
  var url=res.data.redirect
         window.location.href=url
    }else {
      this.$message.error(res.data.message);
    }
  })
}
}
};
</script>
<style lang="less" scoped>
.personal {
  position: relative;
  .top_logo {
    width: 1200px;
    margin: 0 auto;
    .shu {
      height: 50px;
      width: 3px;
      background: #333;
    }
    .right_title {
      display: flex;
      align-items: center;
    }
  }
  .login_zone {
    width: 1200px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .login_box {
      float: right;
      width: 336px;
      height: 390px;
      background: rgba(0, 0, 0, 0.7);
      .login-title {
        text-align: center;
        color: #fff;
        .el-col {
          padding: 10px;
          border-bottom: 3px solid #ffffff;
        }
        .active {
          border-bottom: 3px solid #6eaff7;
          color: #6eaff7;
        }
      }
      .icon_box {
        background: #707070;
        height: 38px;
        img {
          width: 16px;
          height: 18px;
          padding: 10px;
        }
      }
      .login_item {
        margin: 15px 20px;
      }
      input {
        background: #fff;
        height: 36px;
        border: none;
        background: none;
        outline: none;
        margin: 0 5px;
      }
    }
  }

  .forget {
    text-align: right;
    color: #fff;
    font-size: 14px;
    margin-right: 20px;
  }
  .login_btn {
    margin: 15px 20px;
    color: #fff;
    text-align: center;
    padding: 10px;
    background: #6eaff7;
    border-radius: 3px;
    cursor: pointer;
  }
  .registered {
    text-align: right;
    line-height: 66px;
    span {
      background: #004da1;
      padding: 10px 20px;
      color: #fff;
    }
  }
}
</style>