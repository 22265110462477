<template>
<div class="box">
    <el-row class="top">
      <el-col :xs="6" :lg="6" :xl="6"  class="left_tips">
        <div class="grid-content index" @click="go_index">
          <img src="../assets/img/home.png">
          <span>中债通首页</span>
        </div>
        <div class="grid-content">您好，欢迎来到中债通!</div>
      </el-col>
      <el-col  :xs="6" :lg="6" :xl="6" class="right">
        <div class="grid-content">{{date}}  {{week}}</div>
        <div class="grid-content Up" @click="go_up">
          <img src="../assets/img/88.png" class="Upimg">
          <span>客户端下载</span>
        </div>
        <div class="grid-content" style="margin-left: auto;cursor: pointer;" @click="login">登录</div>
      </el-col>
    </el-row>
    </div>
</template>
<script>
export default {
    name:'top',
    data(){
      return{
        date:'',
        week:''
      }
    },
    mounted(){
      this.getTime()
    },
    methods:{
      login(){
        // var url='http://www.zhaitongyun.com'
          this.$router.push('/Business')
        //  window.open(url,'_blank')
      },
      // 前往下载
      go_up(){
        this.$router.push('/Download')
      },
      // 前往首页
      go_index(){
        // this.$router.push('/index')
        this.$router.push('/Business')
      },
      //获取系统当前时间
      getTime() {
      const myDate = new Date();
      const Y = myDate.getFullYear();
      const M = myDate.getMonth() + 1;
      const D = myDate.getDate();
      const W = "星期" + "日一二三四五六".charAt(new Date().getDay());; 
      this.date = Y + "年" + M + "月" + D+ "日"  ;
      this.week=W

    },
    }
}
</script>
<style lang="less" scoped>
.box{
  background: #004da1;
    min-width: 1200px;
}
.top {
  height: 30px;
  // text-align: center;
  line-height: 30px;
  width: 1200px;

  margin: 0 auto;
  .grid-content {
    color: #fff;
    font-size: 14px;
    font-family: Segoe UI;
  }
  .Up{
margin-left: 20px;
display: flex; 
align-items: center;
cursor: pointer;
.Upimg{
      padding: 0 5px;
    width: 10px;
    height: 11px;
    }
  }
  .index{
    display: flex;
    align-items: center;
    padding-right: 50px;
    cursor: pointer;
    img{
      width: 14px;
      height: 14px;
      padding-right: 5px;
    }
  }
  .right{
    float: right;
    display: flex;
    align-items: center;
  }
  .left_tips{
    display: flex;
    align-items: center;
  }
}
</style>